.cardContainer {
  display: flex;
  flex-direction: column;
  background-color: #2193b0;
  color: #fff;
  border-radius: 12px;
  width: 250px;
  height: 80px;
  padding: 8px;
  margin-top: 48px;
  margin-right: 48px;
}

.title {
  font-size: 18px;
  font-weight: 700;
  text-align: center;
  margin-bottom: 10px;
}

.data {
  display: flex;
  align-items: center;
  margin-top: 4px;
  justify-content: center;
}

.number {
  font-size: 22px;
  margin-left: 16px;
}
