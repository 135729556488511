@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@300;700&display=swap");

.spacement {
  margin-left: 0.1vw;
  margin-top: 0.1vh;
  top: 10;
  z-index: 1;
  position: fixed;
  width: 100%;
  height: 100%;
}

.buttonBar {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 4px;
}

.buttons {
  display: flex;
  flex-direction: row;
  width: 70%;
  justify-content: left;
  align-items: center;
}

.rightButtons {
  display: flex;
  flex-direction: row;
  width: 30%;
  justify-content: right;
  align-items: center;
  margin-right: 1vh;
}

.button-Z {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.button-Z span {
  margin-left: 1vh;
}

.separator {
  margin-right: 1vh;
}

.toolBar strong {
  font-family: "Source Sans Pro";
  font-weight: 700;
  font-size: 1vw;
  color: white;
  padding: 10px;
  border-radius: 7px;
  transition: all 0.5s;
  cursor: pointer;
}

.toolBar strong:hover {
  font-family: "Source Sans Pro";
  font-weight: 700;
  font-size: 1vw;
  color: white;
  opacity: 0.5;
  padding: 10px;
  border-radius: 7px;
}

.genesysBox {
  margin-top: 0.3vh;
  height: 100%;
  width: 100%;
  border-radius: 0px 0px 7px 7px;
  color: white;
}

.MuiInput-input {
  color: black;
}

.materialStyle {
  height: 100% !important;
  width: 100% !important;
}

.tabSelect {
  font-weight: 300;
  font-size: calc(8px + 1vw);
  border-radius: 7px;
  height: calc(8px + 4.6vh);
  width: calc(8px + 30vw);
  margin-bottom: 2vh;
  padding-right: 10px;
  padding-left: 10px;
  outline: none;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.tabDivision {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.tabSend {
  border-radius: 6px;
  cursor: pointer;
  color: white;
  border: none;
  font-size: calc(8px + 1vw);
  width: calc(8px + 8vw);
  height: calc(8px + 6vh);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  transition: all 0.4s;
  background-color: #1abc9c;
  margin-right: 2vh;
}

.tabSend:hover {
  background-color: #16a085;
}

.tabClean {
  border-radius: 6px;
  cursor: pointer;
  color: white;
  border: none;
  font-size: calc(8px + 1vw);
  width: calc(8px + 8vw);
  height: calc(8px + 6vh);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  transition: all 0.4s;
  background-color: #e74c3c;
}

.tabClean:hover {
  background-color: #c0392b;
}

/* remover */
.recorderOpen {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border: none;
  background-color: #1abc9c;
  border-radius: 6px;
  max-height: 47px;
  width: calc(12px + 10vw);
  min-width: 7vw;
  min-height: 5vh;
  height: calc(12px + 3vh);
  font-size: calc(8px + 1vw);
  cursor: pointer;
  transition: all 0.7s;
  color: white;
}

.recorderOpen:hover {
  background-color: #16a085;
}

.border-red {
  border: 2px solid #fc1c03;
  color: #fd1900;
}

.border-green {
  border: 2px solid rgb(83, 255, 83);
  color: greenyellow;
}

.border-yellow {
  border: 2px solid yellow;
  color: yellow;
}

.timer-container {
  border-radius: 50px;
  width: 80px;
  height: auto;
  display: flex;
  justify-content: center;
  font-size: 13px;
}
/* remover */
