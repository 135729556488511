.dashHibernation {
  display: flex;
  flex-direction: column;
  padding-left: 7vw;
  padding-top: 2vh;

  font-family: "Source Sans Pro";
  font-size: 1.5vw;
  margin-top: -1vh;
}

.graphicTitleSend {
  background-color: #218da8;
  text-align: center;
  color: #fff;
  padding-block: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 400;
}

.totCard {
  width: 20%;
  height: 4.5rem;
  background: #43b8db;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.totCardHeader {
  border-bottom: 1px solid #6dd5ed;
  padding: 5px 10px;
}
.totCardHeader p {
  font-size: 20px;
  font-weight: 400;
  color: #fff;
  text-align: center;
}

.totCardBody {
  display: flex;
  align-items: center;
  justify-content: center;
}
.totCardBody h3 {
  font-size: 36px;
  margin: 0;
  color: #fff;
  margin-top: -0.5rem;
}

.transiction {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 98%;
}

.fullBox {
  margin-top: 3vh;
  width: 100%;
  height: 100%;
  display: flex;
}

.internalBox {
  width: 98%;
  height: 80vh;
  max-height: 800px;
  background: white;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  overflow: hidden;
}

.fullSizeBox {
  width: 100%;
  height: 80vh;
  max-height: 870px;
  background: white;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  overflow: hidden;
}

.totalRow {
  width: 20%;
  background-color: #2980b9;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px 0px 0px 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
}

.totalValue {
  width: 80%;
  background-color: white;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 0px 5px 5px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
}

.graphicZone {
  height: 92%;
  max-height: 700px;
}

@media only screen and (max-width: 1300px) {
  .dashHibernation {
    display: flex;
    flex-direction: column;
    padding-left: 10vw;
    padding-top: 3vh;
    font-weight: 300;
    font-family: "Source Sans Pro";
    font-size: 1.5vw;
    margin-top: unset !important;
  }
  .totCardHeader p {
    font-size: 16px;
  }
  .totCardBody h3 {
    font-size: 28px;
    margin-top: 0.2rem;
  }
}
