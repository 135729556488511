.tableHeight {
  margin-top: 2vh;
  width: 100%;
  height: 80vh;
  max-height: 800px;
  border-radius: 0px 0px 7px 7px;
  color: white;
}

.modalContainer {
  width: 100%;
  margin: 0 auto;
  max-width: 500px;
}

.addIconButton {
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  margin-top: 8px;
  background: #1abc9c;
  cursor: pointer;
  transition: filter 1s;
}
.addIconButton:hover {
  filter: brightness(80%);
}

.deleteButton {
  padding-inline: 8px;
  margin: 0;
  border: none;
  background: transparent;

  cursor: pointer !important;
  transition: filter 1s;
}

.deleteButton:hover {
  filter: brightness(80%);
}

.deleteButtonHSM {
  padding: 0;
  margin: 0;
  border: none;
  background: transparent;

  cursor: pointer;
  transition: filter 1s;
}
.deleteButtonHSM:hover {
  filter: brightness(80%);
}

.HSMRules {
  max-width: 90%;
  margin: 0 auto;
  border: 1px solid #cdcdcd;
  padding-inline: 16px;
  padding-top: 12px;
}

.mbN5 {
  margin-bottom: -12px;
}
