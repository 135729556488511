@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@300;700&display=swap");

.wrapups {
  display: flex;
  flex-direction: column;
  padding-left: 12.5vw;
  padding-top: 2vh;
  font-weight: 300;
  font-family: "Source Sans Pro";
  font-size: 1.5vw;
  margin-top: -8vh;
}

.wrapups .slBottomBox {
  margin-top: 2vh;
  width: 100%;
  height: 80vh;
  max-height: 800px;
  border-radius: 0px 0px 7px 7px;
  color: white;
}

.addTab{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 30vw;
}

.selectorTab{
  font-family: "Source Sans Pro";
  font-weight: 300;
  font-size: 0.8vw;
  margin-right: 1vh;
  border-radius: 7px;
  height: 4.6vh;
  width: 25vw;
  margin-right: 2vh;
  padding-right: 10px;
  padding-left: 10px;
  transition: ease-out 0.5s;
  margin-bottom: 2vh;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.addTab span{
  font-size: 1vw;
}

.midiaSendBtn {
  width: calc( 12px + 15vw);
  height: calc( 6px + 8vw);
  font-size: calc( 12px + 1.8vw );
  max-width: 250px;
  max-height: 75px;
  background-color: #1abc9c;
  color: white;
  border-radius: 6px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  transition: all 0.7s;
}

.midiaSendedBtn{
  width: calc( 12px + 15vw);
  height: calc( 6px + 8vw);
  font-size: calc( 12px + 1.8vw );
  background-color: #1abc9c;
  color: white;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  background-color: #95a5a6;
}

.midiaSendBtn:hover {
  background-color: #0f6c5a;
}

.titleCentering{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.saveBtn {
  width: 7vw;
  height: 4vh;
  max-height: 60px;
  max-width: 200px;
  font-size: 1.2rem;
  background-color: #1abc9c;
  color: white;
  border-radius: 6px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  transition: all 0.7s;
  margin-bottom: 3vh;
}

.saveBtn:hover {
  background-color: #0f6c5a;
}

.blockedSaveBtn {
  width: 7vw;
  height: 4vh;
  max-height: 60px;
  max-width: 200px;
  font-size: 1.5rem;
  background-color: #95a5a6;
  color: white;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  transition: all 0.7s;
  margin-bottom: 3vh;
}

/* ------------------------------------------- */

@media only screen and (max-width: 1300px) {
  .wrapups {
    display: flex;
    flex-direction: column;
    padding-left: 2vw;
    padding-top: 3vh;
    font-weight: 300;
    font-family: "Source Sans Pro";
    font-size: 1.5vw;
    margin-top: unset !important;
  }
}