.tables-wrapper {
  width: 80%;
  background: rgba(196, 196, 196, 0.1);
  border: 1px solid rgba(41, 128, 185, 0.25);
  box-sizing: border-box;
  border-radius: 6px;
  margin-top: 1vh;
  margin-bottom: 2vh;
}

.tables-wrapper input {
  height: 2vh;

  background: none;
  border: none;
  box-sizing: border-box;
  border-radius: 6px;

  text-align-last: center;
  text-align: center;
  -ms-text-align-last: center;
  -moz-text-align-last: center;
}

.tables-wrapper input:disabled {
  color: black;
}

.MuiTablePagination-displayedRows,
.MuiTablePagination-selectLabel {
  color: black;
}

.MuiTypography-body1 {
  font-size: 12px !important;
}
