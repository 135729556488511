@import url(./spacement.css);
@import url(./flexBox.css);
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700&family=Source+Sans+3:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500&display=swap");

body {
  padding: 0;
  margin: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(52, 73, 94, 0.15);
  overflow-x: hidden;
}

.fw-bold {
  font-weight: 700;
}

.input-bootstrap {
  padding: 6px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  width: 100% !important;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  font-family: "Open Sans", sans-serif;
  font-family: "Source Sans 3", sans-serif;
}
.input-bootstrap:focus {
  outline: none;
  box-shadow: 0 0 5px #007bff;
}
.input-bootstrap::placeholder {
  color: #dfdfdf;
}

.select-bootstrap {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #fff;
  cursor: pointer;
  font-family: "Open Sans", sans-serif;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

/* .select-bootstrap:hover {
  overflow: visible;
  white-space: normal;
  text-overflow: clip;
}

.select-bootstrap[title]:hover:after {
  content: attr(title);
  display: block;
  position: absolute;
  background: rgba(0, 0, 0, 0.8);
  color: #fff;
  padding: 5px;
  border-radius: 5px;
  left: 100%;
  top: 0;
  white-space: nowrap;
} */

.select-bootstrap:focus {
  outline: none;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
}

.select-bootstrap:disabled {
  cursor: not-allowed;
}

.select-bootstrap option {
  font-size: 16px;
  padding-inline: 12px;
  cursor: pointer !important;
}

.text-area-bootstrap {
  margin: 0;
  padding: 0.375rem 0.75rem;
  min-height: 60px;
  border: 1px solid #ced4da;
  color: #495057;
  background-color: #fff;
  transition: box-shadow 0.15s ease-in-out;
  font-family: "Open Sans", sans-serif;
}

.text-area-bootstrap:focus {
  border-color: #007bff;
  box-shadow: 0 0 0 0.25rem rgba(0, 123, 255, 0.25);
  outline: none;
}

.titlePage {
  font-size: 36px;
  font-weight: 400;
}

.btn {
  display: inline-block;
  padding: 10px 20px;
  font-size: 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: filter 2s;
  background: transparent;
}
option[disabled] {
  cursor: not-allowed;
}
.btn-primary {
  background-color: #007bff;
  color: #fff;
}

.btn:focus {
  outline: none;
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
}

.btn:hover {
  filter: brightness(80%);
}

.btn:disabled {
  opacity: 0.4;
  cursor: not-allowed;
}
.disabled {
  opacity: 0.6;
  cursor: not-allowed;
}

.btn-size {
  min-width: 100%;
}
.containerBtn-4 {
  min-width: 40%;
}

.containerLabel {
  width: 100%;
  text-align: start;
  font-family: "Open Sans", sans-serif;
}

.defaultLabel {
  font-size: 16px;
  font-weight: 400;
  font-family: "Open Sans", sans-serif;
  text-transform: lowercase;
  display: inline-block;
}

.defaultLabel::first-letter {
  text-transform: uppercase;
}

.hover-class {
  transition: filter 1s;
}
.hover-class:hover {
  filter: brightness(80%);
}
.pointer {
  cursor: pointer;
}

.slBottomBox {
  margin-top: 2vh;
  width: 100%;
  height: 80vh;
  max-height: 800px;
  border-radius: 0px 0px 7px 7px;
  color: white;
}

.container {
  display: flex;
  flex-direction: column;
  padding-left: 7vw;
  padding-top: 2vh;
  font-weight: 400;
  font-family: "Source Sans Pro";
  font-size: 1.5vw;
  margin-top: -1vh;
  /* width: 92vw;
  max-width: 92vw; */
  position: relative;
}

.w-100 {
  width: 100%;
}
.w-75 {
  width: 75%;
}
.h-100 {
  height: 100%;
}

.time-bootstrap {
  padding: 0.1rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  width: 100%;
  display: inline-block;
  vertical-align: middle;
  text-align: center;
  max-width: 90px;
}

/* Estilize a seta do input type time (navegador Chrome) */
.time-bootstrap::-webkit-inner-spin-button,
.time-bootstrap::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Estilo de foco semelhante ao Bootstrap */
.time-bootstrap:focus {
  border-color: #80bdff;
  box-shadow: 0 0 0 0.25rem rgba(0, 123, 255, 0.25);
  outline: none;
}

.hover-text {
  transition: all 1s;
}

.hover-text:hover {
  filter: brightness(150%);
  color: #616161;
}

.btn-sm {
  padding: 5px 10px !important;
  font-size: 15px;
}

.btn-danger {
  background-color: #f02b3f !important;
  color: #fff !important;
}

.ps-sidebar-container {
  background-color: rgba(0, 0, 0, 0) !important;
}
.ps-sidebar-container::-webkit-scrollbar {
  display: none;
}
.ps-submenu-content {
  /* background: linear-gradient(to bottom, #43b8db, #0052cc) !important; */
  background: #218da8 !important;
  z-index: 999 !important;
  position: fixed !important;
}
.ps-menu-button {
  transition: filter 1s;
}
.ps-menu-button:hover {
  background-color: transparent !important;
  filter: brightness(80%);
}

.MuiTablePagination-selectLabel,
.MuiSelect-select,
.MuiTablePagination-displayedRows {
  color: #fff !important;
}

@media screen and (max-width: 1300px) {
  .titlePage {
    font-size: 24px;
  }

  .defaultLabel {
    font-size: 12px;
  }

  .container {
    padding-left: 10vw;
  }

  .input-bootstrap {
    padding: 4px;
    font-size: 14px;
  }

  .select-bootstrap {
    padding: 4px;
    /* font-size: 14px; */
  }
}
