@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@300;400;700&display=swap");

.containerHibernation {
  width: 100% !important;
  max-width: 475px !important;
  padding: 0 12px 8px 12px;
  font-family: "Source Sans Pro";
  color: #000;
  position: relative;
  margin: 0 auto;
}

.agentPriorize {
  max-width: 80px;
  cursor: pointer;
}

.toggleWrap svg {
  font-size: 3vw;
  margin-left: 1vw;
  cursor: pointer;
}

.btnAdd {
  cursor: pointer;
  border: none;
  border-radius: 50%;
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.selectTimer {
  width: 100%;
  height: 5vh;
  max-height: 50px;
  font-size: calc(4px + 0.7vw);
  text-align-last: start;
  text-align: start;
  -ms-text-align-last: start;
  -moz-text-align-last: start;
  padding-left: 8px;

  background: rgba(196, 196, 196, 0.1);
  border: 1px solid rgba(41, 128, 185, 0.25);
  box-sizing: border-box;
  border-radius: 6px;
  margin-top: 1vh;
  margin-bottom: 2vh;
}

.selectWrap {
  width: 100%;
  height: 5vh;
  min-width: 400px;
  max-height: 50px;
  font-size: calc(4px + 0.7vw);
  text-align-last: start;
  text-align: start;
  -ms-text-align-last: start;
  -moz-text-align-last: start;
  padding-left: 8px;

  background: rgba(196, 196, 196, 0.1);
  border: 1px solid rgba(41, 128, 185, 0.25);
  box-sizing: border-box;
  border-radius: 6px;
  margin-top: 1vh;
  margin-bottom: 2vh;
}
.wraping {
  display: flex;
  flex-direction: column;
  align-items: start;
}
.select-news {
  padding-left: 8px;
}

.toggle {
  display: flex;
  align-items: center;
}

.lastAgent {
  padding-bottom: 9px;
}

.btnExclude {
  padding: 0;
  background: transparent;
  border: none;
  cursor: pointer;
}
