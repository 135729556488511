.containedbtn {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.containedbtn svg {
  margin-right: 1vh;
}

.notText {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 0.1vh;
}

.test {
  display: flex;
  width: 100%;
  height: 100%;
}

.listSelect {
  color: black;
  transition: all ease-in 0.2s;
  background-color: white;
  cursor: pointer;
}

.listSelect:hover {
  background-color: #ecf0f1;
}

.searchableList {
  background-color: none;
}