@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,400;0,700;1,400;1,500;1,700&display=swap");

.container-chat {
  max-width: 100%;
  overflow-x: hidden;
  overflow-y: scroll;
  padding: 12px;
  position: relative;
  background-color: rgb(230, 230, 230);
  font-family: "Roboto", sans-serif !important;
}
.container-chat::-webkit-scrollbar {
  display: none;
}

.container-ballon {
  display: flex;
  align-items: start;
  max-width: 100%;
  border-radius: 10px;
  position: relative;
}

.data {
  font-size: 10px;
  margin-bottom: 6px;
  margin-top: 0px;
  padding-top: 2px;
  color: #000;
}
.mb-4 {
  margin-bottom: 24px;
}

.type {
  font-size: 10px;
  margin-bottom: 0px;
  margin-bottom: 0px;
  padding-bottom: 6px;
  padding-top: 4px;
  color: #fff;
}
.text {
  font-size: 14px;
  color: #000;
  margin-bottom: 8px;
  margin-top: 0px;
  width: fit-content;
}
.side-r {
  flex-direction: row-reverse;
}

.agent {
  max-width: 50%;
  background: #8cc6d0;

  padding-inline: 16px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  position: relative;
}

.bot {
  background-color: #02a2b8;
}
.agent .type {
  color: #000;
}

.bot .type {
  color: #fff !important;
}

.bot .data {
  color: #fff !important;
}
.bot .text {
  color: #fff !important;
}

.agent::before {
  border: 5px solid transparent;
  border-right-color: #8cc6d0;

  content: " ";
  height: 0;
  pointer-events: none;
  position: absolute;
  right: 100%;
  top: 15px;
  width: 0;
  margin-top: -5px;
}

.agent::after {
  border: 5px solid transparent;
  border-right-color: #8cc6d0;
  content: " ";
  height: 0;
  pointer-events: none;
  position: absolute;
  right: 100%;
  top: 15px;
  width: 0;
  margin-top: -5px;
}
.bot.agent::before {
  border: 5px solid transparent;
  border-right-color: #41aebd;
  content: " ";
  height: 0;
  pointer-events: none;
  position: absolute;
  right: 100%;
  top: 15px;
  width: 0;
  margin-top: -5px;
}
.bot.agent::after {
  border: 5px solid transparent;
  border-right-color: #41aebd;
  content: " ";
  height: 0;
  pointer-events: none;
  position: absolute;
  right: 100%;
  top: 15px;
  width: 0;
  margin-top: -5px;
}

.client {
  max-width: 50%;
  background: #008e9f;
  padding-inline: 8px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  position: relative;
  min-width: 15%;
}

.client::after {
  border: 5px solid transparent;
  border-right-color: #5a7976;
  content: " ";
  height: 0;
  pointer-events: none;
  position: absolute;
  left: 100%;
  top: 15px;
  width: 0;
  margin-top: -5px;
  transform: rotate(180deg);
}
.client::before {
  border: 5px solid transparent;
  border-right-color: #5a7976;
  content: " ";
  height: 0;
  pointer-events: none;
  position: absolute;
  left: 100%;
  top: 15px;
  width: 0;
  margin-top: -5px;
  transform: rotate(180deg);
}

.client .text {
  font-size: 14px;
  color: #fff;
  margin-bottom: 8px;
  margin-top: 0px;
  width: fit-content;
}

.query-text {
  background-image: linear-gradient(to right, yellow, yellow 100%);
  background-size: contain;
  background-repeat: no-repeat;
  font-weight: 700;
  color: #000;
}

.client .data {
  font-size: 10px;
  margin-bottom: 6px;
  margin-top: 0px;
  padding-top: 2px;
  color: #fff;
}

.data-container {
  display: flex;
  width: 100%;
  justify-content: end;
}

.pl {
  padding-left: 8px;
}
.pr {
  padding-right: 8px;
}
