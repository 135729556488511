@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@300;700&display=swap");

.sendList {
  display: flex;
  flex-direction: column;
  padding-left: 7vw;
  padding-top: 2vh;

  font-family: "Source Sans Pro";
  font-size: 1.5vw;
  margin-top: -1vh;
}

.title {
  font-size: calc(12px + 1.2vw);
}

.rightSide {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.sendList .toolBar {
  display: flex;
  flex-direction: row;
  width: 98%;
  justify-content: space-between;
  align-items: center;
  padding-top: 5px;
}

.sendList .slBottomBox {
  margin-top: 2vh;
  width: 98%;
  height: 80vh;
  max-height: 800px;
  border-radius: 0px 0px 7px 7px;
  color: white;
}

.compacting {
  width: 96%;
  height: 87vh;
}

.send {
  margin-right: 1vh;
  padding-inline: 8px;
}

.uploadArchive {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: 30vh;
  max-height: 300px;
}

.uploadArchive .attachButton {
  background-color: #1abc9c;
  color: white;
  align-items: center;
  justify-content: center;
  margin-top: 2vh;
}

.uploadArchive .attachButton:hover {
  background-color: #16a085;
}

.uploadArchive .attachButton .MuiSvgIcon-root {
  color: white;
}

.listName {
  margin-top: 4vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.listName input[type="text"] {
  width: 26vw;
  height: 5vh;
  max-height: 50px;
  max-width: 500px;

  background: rgba(196, 196, 196, 0.1);
  border: 1px solid rgba(41, 128, 185, 0.25);
  box-sizing: border-box;
  border-radius: 6px;
  margin-left: 1vw;
  margin-top: 1vh;
}

.numberSend {
  margin-top: 4vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.numberSend select {
  width: 26vw;
  height: 5vh;
  max-height: 50px;
  max-width: 500px;

  background: rgba(196, 196, 196, 0.1);
  border: 1px solid rgba(41, 128, 185, 0.25);
  box-sizing: border-box;
  border-radius: 6px;
  margin-top: 1vh;
}

.messageSend {
  margin-top: 4vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.messageSend select {
  width: 26vw;
  height: 5vh;
  max-height: 50px;
  max-width: 500px;
  margin-top: 1vh;

  background: rgba(196, 196, 196, 0.1);
  border: 1px solid rgba(41, 128, 185, 0.25);
  box-sizing: border-box;
  border-radius: 6px;
}

.paramSend {
  margin-top: 2vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.paramSend select {
  width: 26vw;
  height: 5vh;
  max-height: 50px;
  max-width: 250px;
  margin-top: 1vh;

  background: rgba(196, 196, 196, 0.1);
  border: 1px solid rgba(41, 128, 185, 0.25);
  box-sizing: border-box;
  border-radius: 6px;
}

.messageFinal {
  margin-top: 4vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.sendFinalBtn {
  margin-top: 2vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 4vh;
}

@media only screen and (max-width: 1300px) {
  .sendList {
    display: flex;
    flex-direction: column;
    padding-left: 10vw;
    padding-top: 3vh;
    font-weight: 300;
    font-family: "Source Sans Pro";
    font-size: 1.5vw;
    margin-top: unset !important;
  }
}
