.stylingTools {
  margin-top: 3vh;
  width: 100%;
}

/* .toolsCentral {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 2vh;
} */

.toolsContainer {
  padding-left: 7vw;
  padding-top: 2vh;
  font-weight: 400;
  font-family: "Source Sans Pro";

  margin-top: -1vh;
  width: 93vw;
  max-width: 93vw;
  position: relative;
}

.container {
  max-width: 80%;
  display: flex;

  margin-top: 24px;
  align-items: start;
  flex-wrap: wrap;
  gap: 24px;
}

.cardUnblock {
  min-width: 300px;
}

.cardConfig {
  min-width: 375px;
}

@media screen and (max-width: 1300px) {
  .toolsContainer {
    padding-left: 10vw;
  }
}
