.containerUpload {
  display: flex;
  align-items: center;
  justify-content: left;
  max-width: 100%;
  width: 32rem;
  padding-block: 6px;
  padding-inline: 4px;
  border: 1px solid #e6e6e6;
  background: #ededed;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}

.midiaImage {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
}

.midiaName {
  display: flex;
  align-items: center;
  justify-content: left;
  width: 80%;
}

.iconFeedbacks {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  margin-right: 1.5vh;
}

.midiaName {
  font-size: calc(6px + 1.2vw);
}
