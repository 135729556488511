.cmBoxes {
  width: 23%;
  height: 13vh;
  max-height: 180px;
  background: #43b8db;
  padding: 8px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  margin-bottom: 1vh;
  color: #fff;
}

.cmBoxesHeader {
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid #fff;
  padding-bottom: 2px;
}

.cmBoxesBody {
  display: flex;
  align-items: center;
  justify-content: center;
}

.cmBoxesBody p {
  font-size: 32px;
}
.cmBoxesFooter {
  display: flex;
  align-items: center;
  justify-content: center;
}
.cmBoxesFooter p {
  font-size: 13px;
}

.cmBoxesTitle {
  font-size: 20px;
}

.cmInnerBox {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
}

.centralGraphic {
  height: 100%;
  width: 80%;
  max-height: 700px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.legendGraphic {
  display: flex;
  flex-direction: row;
}

.bgGraph {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 1vh;
  width: 98%;
  height: 67vh;
  max-height: 730px;
  background: white;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  overflow: hidden;
}

.values {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 98%;
  margin-block: 12px;
}

.containerTimer {
  max-width: 200px;
}

.containerSelect {
  max-width: 275px;
}
.graphicTitleSend {
  background-color: #007bff;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-block: 4px;
  color: #fff;
}
