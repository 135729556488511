.wordbox{
  width: 80%;
  margin: 0 auto;
}
.feeling{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 12vh;

  background: rgba(196, 196, 196, 0.1);
  border: 1px solid rgba(41, 128, 185, 0.25);
  box-sizing: border-box;
  border-radius: 6px;
  margin-bottom: 2vh;
  padding-block: 48px;
}

.alignBoxes{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.includes{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 12vh;

  background: rgba(196, 196, 196, 0.1);
  border: 1px solid rgba(41, 128, 185, 0.25);
  box-sizing: border-box;
  border-radius: 6px;
}


.feeling label{
  margin-bottom: 1vh;
}