@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@300;700&display=swap");

.configBox, .configTabs {
  width: 100%;
  height: 100%;
}

.contentBox {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: white;
  box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
  border-radius: 0px 0px 7px 7px;
  width: 100%;
  padding-block: 16px;
}

.midiaBlock textarea{
  width: 100%;
  height: 10vh;
  max-height: 100px;

  background: rgba(196, 196, 196, 0.1);
  border: 1px solid rgba(41, 128, 185, 0.25);
  box-sizing: border-box;
  border-radius: 6px;
  margin-top: 1vh;
  margin-bottom: 2vh;
}

.boxTitle {
  display: flex;
  color: white;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: #007bff;
  box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
  border-radius: 7px 7px 0px 0px;
  width: 100%;
}

.boxTitle svg {
  margin: 0.7vw;
}

.paneMsg, .midiaBlock{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 80%;
  cursor: pointer;
}

.paneMsg .paneChecked {
  display: flex;
  background: rgba(196, 196, 196, 0.1);
  align-items: center;
  justify-content: center;
  margin-top: 1vh;
  border-radius: 6px;
  width: 100%;
  height: 5vh;
  border-color: #2980b9;
  border-style: solid;
  border-width: 1px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  user-select: none;
  margin-bottom: 2vh;
  cursor: pointer;
}

.paneMsg .paneUnchecked {
  display: flex;
  background: rgba(196, 196, 196, 0.1);
  align-items: center;
  justify-content: center;
  margin-top: 1vh;
  border-radius: 6px;
  height: 5vh;
  width: 100%;
  border-color: rgba(41, 128, 185, 0.25);
  border-style: solid;
  border-width: 1px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  user-select: none;
  margin-bottom: 2vh;
  cursor: pointer;
}

.checkerHidder {
  visibility: hidden;
  position: fixed;
}