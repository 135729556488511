@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@300;400;500;700&display=swap");

.card-monitoring {
  width: 20rem;
  height: auto;
  background-color: #fdfdfd;
  border-radius: 12px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.card-header-monitoring {
  padding: 16px;
  border-bottom: 1px solid #ededed;
  text-align: center;

}
.card-body-monitoring-icon{
  position: absolute;
  left: 50%;
  transform: translateX(-40%);
  top: 50%;
 font-size: 30px !important;
}
.card-body-monitoring-icon-danger{
  color: rgb(238, 0, 0);
}
.card-body-monitoring-icon-alert{
  color: rgb(255, 238, 0);
}
.card-body-monitoring-icon-success{
  color: rgb(0, 218, 0);
}

.card-header-monitoring span {
  font-size: calc(12px + 0.8vw);
  font-weight: 500;
}

.card-body-monitoring {
  position: relative;
  padding: 16px;
  border-bottom: 1px solid #ededed;
  min-height: 170px;
}
.card-footer-monitoring {
  padding: 16px;
}

.card-footer-monitoring span {
  font-size: calc(12px + 0.8vw);
  font-weight: 500;
}

.card-footer-monitoring .card-footer-status {
  text-transform: capitalize;
}

.card-footer-monitoring-error {
  color: rgba(255, 0, 0, 1);
}

.card-footer-monitoring-danger {
  color: rgb(201, 197, 2);
}

.card-footer-monitoring-success {
  color: rgb(0, 168, 0);
}
