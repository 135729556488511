.container {
  background: rgb(248, 248, 248);
  max-width: 100%;
  padding: 48px;
}

.documentButton {
  background: transparent;
  padding: 0;
  border: none;
  cursor: pointer;
  margin-top: 8px;
  margin-bottom: 16px;
  margin-right: 14px;
  color: blue;
}

.title {
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 16px;
}
