.unblockContainer {
  width: 100%;
}

.blockBox {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: white;
  box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
  border-radius: 0px 0px 7px 7px;
  width: 100%;
  max-width: 400px;
}

/* .unblockForm {
  max-width: 400px;
} */

.titleBlockBox {
  display: flex;
  color: white;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: #218da8;
  box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
  border-radius: 7px 7px 0px 0px;
  width: 100%;
  max-width: 400px;
}

.titleBlockBox svg {
  margin: 0.7vw;
}

.blockBox label {
  margin-top: 1vw;
  font-size: 16px !important;
  font-weight: 500;
  margin-left: 4px;
}

.blockBox input {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  width: calc(100% - 50px);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}

.selectBlock {
  width: calc(100% - 30px);
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #fff;
  cursor: pointer;
  margin-bottom: 24px;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}

.selectBlock:focus {
  outline: none; /* Remove o contorno padrão quando o select está focado */
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
}

.unblockBtn {
  margin-bottom: 1vh;
  width: 15rem;
  height: 2.5rem;
  max-width: 180px;
  font-size: 20px;
  background-color: #0d6efd;
  color: white;
  border-radius: 6px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  transition: all 0.7s;
  margin-bottom: 16px;
}

.unblockBtn:hover {
  height: 2.5rem;
  width: 15rem;
  max-width: 180px;
  font-size: 20px;
  background-color: #0347ad;
  color: white;
  border-radius: 6px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
}

.divLabelTools {
  width: calc(100% - 20px);
  max-width: 400px;
  display: flex;
  justify-content: start;
}
