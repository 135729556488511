.interactionToolbar {
  display: flex;
  flex-direction: row;
  width: 98%;
  justify-content: space-between;
  align-items: center;
}

.dashTable {
  border-radius: 7px;
  background-color: #218da8;
  width: 98%;
  margin-top: 1vh;
  height: 80vh;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.dashTable thead {
  color: white;
  justify-content: space-between;
  font-weight: 700;
  font-size: 14px;
}

.dashTable tbody {
  background-color: white;
  color: black;
  justify-content: space-between;
  font-weight: 400;
  font-size: 16px;
}

.dashTable td {
  text-align: right;
  padding-right: 5px;
}

.dashTable td:first-child {
  text-align: center;
  padding-right: 0;
}

.dashTable th {
  width: 8%;
}

.dashTable th:first-child {
  width: 4%;
}

/* ------------------------------------------- */

@media only screen and (max-width: 1300px) {
  .dashTable tbody {
    font-size: 14px;
  }
  .dashTable {
    height: 70vh;
  }
}
