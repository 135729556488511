.analiticsField {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
}

.inputContainer {
  max-width: 98%;
}

.timerContainer {
  max-width: 40%;
}
