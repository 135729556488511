.containerSelectHeader {
  min-width: 170px !important;
  display: flex;
}
.containerSelect {
  min-width: 170px;
}

.containerMonth {
  width: 160px;
}

.containerRelative {
  position: relative;
  z-index: 1;
}

.chartLineContainer {
  background-color: #fcfcfc;
  width: 48%;
  border-radius: 20px;
  padding: 8px;
}

.chartLineContainer:first-of-type {
  margin-right: 48px;
}

.pieContainer {
  width: 45%;
  background: #fcfcfc;
  padding: 8px;
  border-radius: 20px;
}

.pieContainerWhats {
  width: 367px;
  background: #fcfcfc;
  padding: 8px;
  border-radius: 20px;
  margin-right: 48px;
  height: 320px;
}

.dayContainer {
  background-color: #fcfcfc;
  padding: 8px;
  border-radius: 20px;
  width: 800px;
  height: 320px;
}

.marginNegative {
  margin-left: -48px;
}

.pieContainer,
.pieContainerWhats,
.dayContainer,
.chartLineContainer p {
  font-weight: 700;
}

.lineContainer {
  background-color: #fcfcfc;
  padding: 8px;
  border-radius: 20px;
  width: 95%;
}

.barContainer {
  background-color: #fcfcfc;
  padding: 8px;
  border-radius: 20px;
}

.customCard {
  position: absolute;
  top: 50px;
  background-color: #fcfcfc;
  padding: 24px 16px;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.customCard p {
  font-size: 20px;
  margin-bottom: 16px;
}

.customCard span {
  font-weight: 700;
}
