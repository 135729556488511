.uraBox {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 98%;
  height: 60vh;
  background: white;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 0px 0px 6px 6px;
  margin-right: 1vw;
}

.textMinor {
  font-size: 32px;
}

.uraTitle {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 98%;
  height: 4.5vh;
  max-height: 45px;
  margin-top: 3vh;
  background-color: #218da8;
  border-radius: 5px 5px 0px 0px;
  color: white;
}

.statusUra {
  display: flex;
  flex-direction: row;
  width: 98%;
  padding-top: 2vh;
  justify-content: space-between;
  align-items: flex-end;
}

.uraBoxes {
  background: #43b8db;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  max-height: 20rem;
  padding-inline: 16px;
  padding-block: 4px;
  width: 275px;
}

.uraBoxesHeader {
  display: flex;
  align-items: center;
  color: #fff;
  justify-content: center;
}

.uraBoxesHeader p {
  font-size: 22px;
  margin-bottom: 0;
}

.quantity {
  color: #fff;
  text-align: center;
  font-size: 30px;
  margin: 0;
  padding: 0;
}

.value {
  margin: 0;
}

.footer {
  color: #fff;
}

.footer p {
  font-size: 14px;
  text-align: center;
}
.uraGraphic {
  height: 60vh;
  max-height: 700px;
  overflow: hidden;
  width: 100%;
}
.dashContainer {
  padding-left: 7vw;
  padding-top: 2vh;
  margin-top: -1vh;
}

@media screen and (max-width: 1300px) {
  .dashContainer {
    padding-left: 10vw;
  }

  .uraBoxesHeader p {
    font-size: 16px;
  }
  .quantity {
    font-size: 22px;
  }
  .footer p {
    font-size: 12px;
  }
  .uraBoxes {
    width: 225px;
  }
  .textMinor {
    font-size: 22px;
  }
}
